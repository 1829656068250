<template>
  <div class="bg" id="bg">
    <div class="box">
      <div class="explain">
        刷新页面即可随机群友怪话！
      </div>
    </div>
    <div class="box">
      <div class="title"></div>
    </div>
    
    <input class="btn" type="button" value="刷新" @click="fetchImages()" />
    
    <div>
      <img :src="selectedImage" />
      <li v-for="image in selectedImages" :key="image">
        <img :src="image">
      </li>
    </div>

    <ul>
      <li v-for="item in imageCounts" :key="item[0]" @click="fetchImages(item[0])">
        {{ item[0] }}: {{ item[1] }}
      </li>
    </ul>

  </div>

  <div class="drop-area" 
       @dragover.prevent
       @drop="onDrop">
    拖拽图片上传
  </div>
</template>

<style>
img {
  width: 300px;
}
</style>

<script>
import axios from 'axios';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      selectedImages: [],
      imageCounts: []
    };
  },
  mounted() {
    this.fetchImages();

    axios.get('https://api.guanceshu.com/image-counts')
      .then(response => {
        this.imageCounts = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    async fetchImages(name="") {
      try {
        let url = 'https://api.guanceshu.com/images';
        if (name) {
          url += '?name=' + name;
        }
        const response = await axios.get(url);
        this.selectedImages = response.data["data"]
        console.log(this.selectedImages)
      } catch (error) {
        console.error(error);
      }
    },
    getImgUrl: function (image) { 
      return require(""+`${image}`);
    },
    onDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.checkFiles(files);
    },
    async checkFiles(files) {
      const formData = new FormData();
      formData.append('file', files[0]);
      const response = await axios.post('https://api.guanceshu.com/upload', formData);
      console.log(response.data["message"]);
      alert(response.data["message"]);
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
